import styled from '@emotion/styled';
import { FC, HTMLAttributes, ReactNode } from 'react';
import LogoTypes, { LogoType } from './LogoTypes';

interface Props extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  /**
   * 로딩에 쓸 타입을 지정 합니다.
   * @default LOGO
   */
  logo_type?: LogoType;
  /**
   * backdrop 사용 유무를 지정 합니다/
   * @default false
   */
  use_backdrop?: boolean;
}

/**
 * 공통 로딩 사용 컴포넌트
 * @component
 */
const Loading: FC<Props> = ({ children, logo_type = LogoType.LOGO, use_backdrop = false, ...rest }) => {
  const ActiveLogoType = LogoTypes[logo_type];

  if (use_backdrop) {
    return (
      <SC.Backdrop {...rest}>
        <SC.Container>
          <ActiveLogoType />
          {children}
        </SC.Container>
      </SC.Backdrop>
    );
  }

  return (
    <>
      <ActiveLogoType />
      {children}
    </>
  );
};

export default Loading;

const SC = {
  Backdrop: styled.div`
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
  `,
  Container: styled.div`
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    .logo {
      width: 80px;
      height: 80px;
      margin: 0 auto;

      @media screen and (min-width: 450px) {
        width: 90px;
        height: 90px;
      }
    }
  `,
};
