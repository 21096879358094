import Logo from './Logo';
import WaveDots from './WaveDots';

export enum LogoType {
  LOGO = 'LOGO',
  WAVE_DOTS = 'WAVE_DOTS',
}

/**
 * 로딩 종류들
 * @component
 */
const LogoTypes = {
  [LogoType.LOGO]: Logo,
  [LogoType.WAVE_DOTS]: WaveDots,
};

export default LogoTypes;
