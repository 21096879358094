import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React, { forwardRef } from 'react';
import { pink500 } from 'styles';

export interface WaveDotsProps extends React.HTMLAttributes<HTMLDivElement> {}

/**
 * 로딩 - waveDot 타입
 * @component
 */
const WaveDots = forwardRef<HTMLDivElement, WaveDotsProps>((props, ref) => {
  return (
    <SC.Balls ref={ref} {...props}>
      {[...Array(4)].map((_, index) => (
        <div key={index} className='ball' />
      ))}
    </SC.Balls>
  );
});

export default WaveDots;

const loadingAnimation = keyframes`
  from {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(7px);
  }
  75% {
    transform: translateY(-7px);
  }
  to {
    transform: translateY(0px);
  }
`;

const SC = {
  Balls: styled.div`
    width: 44px;
    height: 5px;
    margin: 0 auto;
    text-align: center;
    .ball {
      width: 11.37%;
      height: 100%;
      border-radius: 50%;
      vertical-align: top;
      display: inline-block;
      background-color: ${pink500};
      animation: ${loadingAnimation} 400ms infinite;
      &:nth-of-type(1) {
        animation-delay: 0ms;
      }
      &:nth-of-type(2) {
        animation-delay: 80ms;
      }
      &:nth-of-type(3) {
        animation-delay: 160ms;
      }
      &:nth-of-type(4) {
        animation-delay: 240ms;
      }
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  `,
};
