import styled from '@emotion/styled';
import lottie from 'lottie-web';
import { FC, HTMLAttributes, ReactNode, useRef } from 'react';
import { useMount } from 'hooks';

interface Props extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
}

/**
 * 로딩 -로고 타입
 * @component
 */
const Logo: FC<Props> = ({ children, ...rest }) => {
  const ref = useRef<HTMLDivElement>(null);

  useMount(() => {
    if (ref.current) {
      lottie.loadAnimation({
        container: ref.current,
        loop: true,
        autoplay: true,
        animationData: require('./images/fbk-logo-lottie.json'),
      });
    }
  });

  return <SC.Container ref={ref} {...rest} />;
};

export default Logo;

const SC = {
  Container: styled.div`
    width: 80px;
    height: 80px;
    margin: 0 auto;

    @media screen and (min-width: 450px) {
      width: 90px;
      height: 90px;
    }
  `,
};
